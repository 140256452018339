<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>代理商管理</p>
			<i>/</i>
			<p>代理商角色</p>
		</nav>
	
		<div class="head_search_wrap">
	
			<div class="search_one_line">
				<div class="left">
					<el-form label-width="120px" @submit.native.prevent>
						<el-form-item label="代理角色名称：">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="请输入代理商角色名称"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="激活" value="1"></el-option>
								<el-option label="冻结" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>
	
		</div>
	
		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>代理商角色列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="role_name" label="代理商角色名称" align="left"></el-table-column>
				<el-table-column prop="limit_num" label="企业用户数" align="center"></el-table-column>
				<el-table-column prop="alliance_fee" label="加盟费" align="center"></el-table-column>
				<el-table-column prop="sort" label="排序" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.status == '1'">
							<p class="is_sale_1">激活</p>
						</div>
						<div v-else>
							<p class="is_sale_0">冻结</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">编辑</span>
							<span @click="deleteFn(scope.row)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
	
			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>
	
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'agentRoleList',
		data() {
			return {
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '', // 状态
			}
		},
		components: {
			
		},
		created() {
			// 获取代理商角色列表
			this.getAgentRoleList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.type = '';
				this.getAgentRoleList()
			},
			
			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getAgentRoleList()
			},
			
			// 获取代理商角色列表
			getAgentRoleList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agentrole/list`,
					data: {
						page: this.currentPage1,
						role_name: this.keyword,
						status: this.type,
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},
			
			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
				this.getAgentRoleList();
			},
			
			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/agentRoleEdit',
					query:{
						id: row.id
					}
				})
			},
			
			// 新增
			addFn(){
				this.$router.push('/agentRoleEdit');
			},
			
			// 删除当前代理商角色
			deleteFn(row){
				this.$confirm('确认删除此条信息吗，删除后将无法找回。', '危险提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$sendHttp({
						method: 'POST',
						url: `${process.env.VUE_APP_SAAS}agentrole/del`,
						data: {
							id: row.id
						}
					}).then( res => {
						if(res.code == '0'){
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.listData.forEach( (item, index) => {
								if(item.id == row.id){
									this.listData.splice(index, 1)
								}
							});
						}
					})
				}).catch(() => {});
				
			},
		}
	}
</script>
<style scoped lang="less">

</style>
